import * as React from "react";
import { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

import { FormStepper } from "./FormStepper";
import { FormPersonalInfo } from "./FormPersonalInfo";
import { FormJobInfo } from "./FormJobInfo";
import { ThreeDotsLoading } from "../../ThreeDotsLoading";
import { PersonalInfoValues, JobInfoValues, QuoteValues } from "../types";

import "./FormSteps.scss";

const initialValue: QuoteValues = {
  name: "",
  email: "",
  phoneNumber: "",
  company: "",
  jobDescription: "",
  jobAddress: "",
  jobCity: "",
  jobState: "",
  jobZip: "",
};

interface FormStepsProps {
  onSubmit: (formValues: QuoteValues) => void;
  isLoading: boolean;
  hasError: boolean;
  onClose?: () => void;
}

const FormSteps = ({
  onSubmit,
  isLoading,
  hasError,
  onClose,
}: FormStepsProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [quoteData, setQuoteData] = useState<QuoteValues>(initialValue);

  const handlePersonalSubmit = (values: PersonalInfoValues) => {
    setQuoteData({ ...quoteData, ...values });
    setActiveStep(1);
  };

  const handleJobSubmit = async (values: JobInfoValues) => {
    const formData: QuoteValues = { ...quoteData, ...values };
    setQuoteData(formData);
    onSubmit(formData);
  };

  const handleOnBack = () => {
    setActiveStep(0);
  };

  const renderError = () => {
    return (
      <p className="error-mesg">
        We're sorry something went wrong with your request.
        <br />
        Please try to submit again and if problem continues you can contact us
        directly
        <br />
        <a className="phone" href="tel:+1-781-315-5321">
          781.315.5321
        </a>
        <br />
        <a className="email" href="mailto:cjshaughnessycrane@gmail.com">
          cjshaughnessycrane@gmail.com
        </a>
      </p>
    );
  };

  const renderButtons = () => {
    const renderSecondaryButton = () => {
      if (activeStep === 1) {
        return (
          <Button
            variant="secondary"
            onClick={handleOnBack}
            style={{ marginRight: "10px" }}
          >
            Back
          </Button>
        );
      }

      if (onClose !== undefined) {
        return (
          <Button
            variant="secondary"
            onClick={onClose}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </Button>
        );
      }

      return null;
    };

    return (
      <>
        {hasError && renderError()}
        {renderSecondaryButton()}
        <Button type="submit" form={formId} variant="primary">
          {primaryButtonText}
        </Button>
      </>
    );
  };

  const formId = activeStep === 0 ? "form-1" : "form-2";
  const primaryButtonText = activeStep === 0 ? "Next" : "Submit";

  return (
    <>
      <h5
        className="text-primary"
        style={{ fontWeight: "bold", paddingTop: "1rem" }}
      >
        LETS GET SOME INFORMATION
      </h5>
      <Row>
        <Col xs={12} md={8}>
          <h6>
            Fill out both steps in the form and we will follow up with a custom
            quote for you
          </h6>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormStepper step={activeStep} />
        </Col>
      </Row>
      <FormPersonalInfo onSubmit={handlePersonalSubmit} hide={activeStep > 0} />
      <FormJobInfo
        onSubmit={handleJobSubmit}
        onBack={handleOnBack}
        hide={activeStep < 1}
      />
      <Row>
        <Col>{isLoading ? <ThreeDotsLoading /> : renderButtons()}</Col>
      </Row>
    </>
  );
};

export { FormSteps };
