import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import { NavBar, Footer, ScrollToTop } from "./components";
import {
  Home,
  Equipment,
  About,
  Work,
  Quote,
  Contact,
  Career,
  Contracts,
} from "./routes";

import "./App.scss";

const App = () => (
  <BrowserRouter>
    <ScrollToTop />
    <NavBar />

    <Switch>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/work">
        <Work />
      </Route>
      <Route path="/equipment">
        <Equipment />
      </Route>
      <Route path="/quote">
        <Quote />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Route path="/careers">
        <Career />
      </Route>
      <Route path="/contracts">
        <Contracts />
      </Route>
      <Route exact path="/">
        <Home />
      </Route>
      <Route path="/*">
        <Home />
      </Route>
    </Switch>
    <Footer />
  </BrowserRouter>
);

export default App;
