import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";

import { Stack, Tiles } from "components";

import logo from "images/CJS-Guy.png";

import "./FormSuccess.scss";

const subHeader1 = `We've received your application. Someone from our team will be in contact with you!`;

interface FormSuccessProps {
  onClose?: () => void;
}

const FormSuccess = ({ onClose }: FormSuccessProps) => {
  return (
    <Row>
      <Col>
        <Row bsPrefix="row d-none d-md-flex">
          <Col>
            <Stack direction="row" space={30}>
              <div>
                <div className="aligner"></div>
                <img className="success-logo" alt="crane-man-logo" src={logo} />
              </div>

              <div>
                <h4 className="text-primary" style={{ fontWeight: "bold" }}>
                  Success!
                </h4>
                <p>{subHeader1}</p>
                {onClose !== undefined && (
                  <Button variant="primary" onClick={onClose}>
                    Close
                  </Button>
                )}
              </div>
            </Stack>
          </Col>
        </Row>

        <Row bsPrefix="row d-sm-flex d-md-none tile-row">
          <Col>
            <Stack
              direction="column"
              space={0}
              sx={{
                marginBottom: "50px",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <Tiles columns={1} space="30px">
                <div>
                  <img
                    className="success-logo"
                    alt="crane-man-logo"
                    src={logo}
                  />
                </div>

                <div>
                  <h4 className="text-primary" style={{ fontWeight: "bold" }}>
                    Success!
                  </h4>
                  <p>{subHeader1}</p>
                </div>
              </Tiles>
              {onClose !== undefined && (
                <Button variant="primary" onClick={onClose}>
                  Close
                </Button>
              )}
            </Stack>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export { FormSuccess };
