import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  CjsJumbotron,
  Stack,
  Tiles,
  JobSummaryCard,
  PageHelmet,
} from "components";

import { jobs } from "./jobList";

const Work = () => {
  return (
    <Container fluid>
      <PageHelmet
        title="Our Work - CJ Shaughnessy Crane"
        description="A highlighted list of CJ Shaughnessy's crane, rigging and hauling jobs. Click each one for pictures and details of the crane job."
      />
      <CjsJumbotron
        header="OUR WORK"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <div style={{ marginBottom: "3rem" }}>
          <Row>
            <Col xs={12} md={7}>
              <h3 className="text-primary" style={{ fontWeight: "bold" }}>
                RECENT JOBS
              </h3>
              <p className="description-info">
                Check out some of our recent jobs we've finished. Click on each
                one for more information and photos!
              </p>
            </Col>
          </Row>
        </div>
        <Row>
          <Col>
            <Row bsPrefix="row d-sm-flex d-lg-none">
              <Stack direction="row" space={20} sx={{ padding: "10px" }}>
                {jobs.map((job, index) => {
                  return (
                    <div key={`job-${index}`}>
                      <JobSummaryCard job={job} />
                    </div>
                  );
                })}
              </Stack>
            </Row>

            <Row
              bsPrefix="row d-none d-lg-flex"
              style={{
                marginBottom: "50px",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <Tiles columns={[2, 3]} space="25px">
                {jobs.map((job, index) => {
                  return (
                    <div key={`job-${index}`}>
                      <JobSummaryCard job={job} />
                    </div>
                  );
                })}
              </Tiles>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Work };
