import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import StarIcon from "@material-ui/icons/Star";

import { CjsEvent, ICjsEventProp } from "./CjsEvent";
import { events } from "./events";

import "react-vertical-timeline-component/style.min.css";
import "./CjsTimeline.scss";

const CjsTimeline = () => {
  const newEvents = events.slice(0).reverse();
  return (
    <div className="card">
      <VerticalTimeline>
        {newEvents.map((event: ICjsEventProp, index: number) => {
          return <CjsEvent key={index} {...event} />;
        })}
        <VerticalTimelineElement
          iconStyle={{ background: "rgb(57, 82, 166)", color: "#fff" }}
          icon={<StarIcon />}
        />
      </VerticalTimeline>
    </div>
  );
};

export { CjsTimeline };
