import React from "react";
import { useState } from "react";
import {
  Card,
  ToggleButtonGroup,
  ToggleButton,
  Row,
  Col,
} from "react-bootstrap";

import { Job } from "../../types";
import { TitleSection } from "./TitleSection";
import { DescriptionSection } from "./DescriptionSection";
import { EquipmentSection } from "./EquipmentSection";
import { SpecSection } from "./SpecSection";
import { PhotoCarousel } from "./PhotoCarousel";

import "./JobCard.scss";
interface JobCardProps {
  job: Job;
}

const JobCard = ({ job }: JobCardProps) => {
  const {
    title,
    description,
    date,
    location,
    photoUrls,
    specs,
    equipmentUsed,
  } = job;
  const [selectedView, setSelectedView] = useState("info");

  const handleOnChange = (value: string) => {
    if (value === "photos") {
      setSelectedView("photos");
      return;
    }
    setSelectedView("info");
  };

  const shouldRenderPhotos = selectedView === "photos";

  return (
    <Card className="job-card">
      <Card.Body>
        <Row>
          <Col sm={12} md={8}>
            <TitleSection title={title} location={location} date={date} />
            <Row>
              <Col>
                <ToggleButtonGroup
                  type="radio"
                  name="job options"
                  onChange={handleOnChange}
                  defaultValue="info"
                >
                  <ToggleButton
                    variant="outline-primary"
                    type="radio"
                    name="info"
                    value="info"
                  >
                    Job Details
                  </ToggleButton>
                  <ToggleButton
                    variant="outline-primary"
                    type="radio"
                    name="photos"
                    value="photos"
                  >
                    Photos
                  </ToggleButton>
                </ToggleButtonGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "2rem" }}>
          {shouldRenderPhotos && (
            <Col>
              <PhotoCarousel photoUrls={photoUrls} />
            </Col>
          )}
          {!shouldRenderPhotos && (
            <Col>
              <Row>
                <Col sm={12} md={8}>
                  <DescriptionSection description={description} />
                </Col>
              </Row>
              <Row style={{ marginTop: "2rem" }}>
                <Col md={12} lg={4}>
                  <SpecSection specs={specs} />
                </Col>
                <Col md={12} lg={8}>
                  <EquipmentSection equipment={equipmentUsed} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export { JobCard };
