import * as React from "react";
import { Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import { JobCard } from "../JobCard";

import { Job } from "../../types";

import "./JobModal.scss";

interface JobModalProps {
  show: boolean;
  onHide: () => void;
  job: Job;
}

const JobModal = ({ show, onHide, job }: JobModalProps) => {
  return (
    <Modal size="xl" show={show} centered onHide={onHide} animation={false}>
      <button type="button" className="close" onClick={onHide}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">Close</span>
      </button>
      <Modal.Body>
        <Row>
          <Col>
            <JobCard job={job} />
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export { JobModal };
