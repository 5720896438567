import React from "react";
import { Row, Col } from "react-bootstrap";

import { Stack } from "../../../components";

import "./TitleSection.scss";

interface TitleSectionProps {
  title: string;
  location: string;
  date: string;
}

const TitleSection = ({ title, location, date }: TitleSectionProps) => {
  return (
    <>
      <h3 className="text-primary job-title">{title}</h3>
      <Row>
        <Col>
          <Stack className="job-info" direction="row" space={20}>
            <p>{date}</p>
            <p>{location}</p>
          </Stack>
        </Col>
      </Row>
    </>
  );
};

export { TitleSection };
