import React from "react";
import { InteractionCard, Stack } from "components";

import boomLift from "images/crane-boom2-small.jpg";
import ltm from "images/LTM-Background-Small.jpeg";
import cjsGuy from "images/CJS-Guy.png";

const ExploreRow = () => {
  return (
    <>
      <h3
        className="text-primary"
        style={{ marginBottom: "0", fontWeight: "bold" }}
      >
        EXPLORE OUR SITE
      </h3>
      <p className="sub-header">projects - history - equipment</p>
      <p className="description-info">
        Stick around and check out some of our other pages for more information!
      </p>
      <Stack
        direction="row"
        space={30}
        sx={{ marginLeft: "auto", marginRight: "auto", padding: "2rem" }}
      >
        <div>
          <InteractionCard
            title="EXPLORE OUR RECENT LIFTS"
            href="/work"
            imgAlt="CJS Crane Boom Lift"
            imgUrl={boomLift}
          />
        </div>
        <div>
          <InteractionCard
            title="BROWSE OUR EQUIPMENT"
            href="/equipment"
            imgAlt="CJS Crane LTM Crane"
            imgUrl={ltm}
          />
        </div>
        <div>
          <InteractionCard
            title="LEARN ABOUT US AND OUR HISTORY"
            href="/about"
            imgAlt="CJS Crane Guy Logo"
            imgUrl={cjsGuy}
          />
        </div>
      </Stack>
    </>
  );
};

export { ExploreRow };
