import React from "react";
import { Row, Col } from "react-bootstrap";

interface DescriptionSectionProps {
  description: string;
}

const DescriptionSection = ({ description }: DescriptionSectionProps) => {
  return (
    <Row>
      <Col>
        <h6 style={{ marginBottom: "0px" }}>{description}</h6>
      </Col>
    </Row>
  );
};

export { DescriptionSection };
