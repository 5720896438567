import React from "react";
import { Flex } from "rebass";

interface IStackProps {
  direction: "row" | "column";
  space: number;
  sx?: any;
  alignItems?: string;
  className?: string;
  children?: React.ReactNode;
}

const Stack: React.FC<IStackProps> = (props) => {
  const {
    direction,
    children,
    space,
    sx,
    alignItems = "left",
    className,
  } = props;
  const option = (horizontalOption: string, veriticalOption: string) => {
    return direction === "row" ? horizontalOption : veriticalOption;
  };
  const leadingMargin = `${space}px !important`;
  const trailingMargin = `0 !important`;
  const overflow = direction === "row" ? { overflow: "auto" } : {};

  return (
    <Flex
      className={className}
      flexDirection={direction}
      alignItems={alignItems}
      sx={{
        ...overflow,
        ...sx,
        "& > * + *": {
          position: "relative",
          marginBottom: option("", trailingMargin),
          marginTop: option("", leadingMargin),
          marginRight: option(trailingMargin, ""),
          marginLeft: option(leadingMargin, ""),
        },
      }}
    >
      {children}
    </Flex>
  );
};

export { Stack };
