import React from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";

import "./InteractionCard.scss";

interface InteractionCardProps {
  title: string;
  href: string;
  imgUrl: string;
  imgAlt: string;
}

const InteractionCard = ({
  title,
  href,
  imgAlt,
  imgUrl,
}: InteractionCardProps) => {
  return (
    <Card className="interaction-card">
      <Card.Body>
        <Link to={href}>
          <img alt={imgAlt} className="interaction-card-image" src={imgUrl} />
          <div className="interaction-card-overlay"></div>
          <Row className="interaction-card-details">
            <Col>
              <h3>{title}</h3>
            </Col>
          </Row>
        </Link>
      </Card.Body>
    </Card>
  );
};

export { InteractionCard };
