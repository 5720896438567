import { useState } from "react";
import axios from "axios";
import { ApplicationData } from "./types";

const useFormData = () => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const parseToBase64 = async (file: File) => {
    let parsed: string = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = (e) => resolve(reader.result as string);
      reader.readAsDataURL(file);
    });

    return parsed.split("base64,")[1];
  };

  const submitFormData = async (form: ApplicationData) => {
    try {
      setIsLoading(true);
      setHasError(false);

      if (form.resume === null) {
        return;
      }

      const { resume, ...rest } = form;
      const parsedFile = await parseToBase64(form.resume);

      await axios.post(
        "https://lg31fdei47.execute-api.us-east-1.amazonaws.com/default/cjs-careers-v2",
        { ...rest, resume: parsedFile, resumeName: resume.name }
      );

      setIsLoading(false);
      setShowSuccess(true);
    } catch (err) {
      setIsLoading(false);
      setShowSuccess(false);
      setHasError(true);
    }
  };

  return {
    isLoading,
    hasError,
    showSuccess,
    submitFormData,
  };
};

export { useFormData };
