import * as React from "react";

import "./CareerForm.scss";

const CareerFormError = () => {
  return (
    <p className="error-mesg">
      We're sorry something went wrong with your request.
      <br />
      Please try to submit again and if problem continues you can contact us
      directly
      <br />
      <a className="phone" href="tel:+1-781-315-5321">
        781.315.5321
      </a>
      <br />
      <a className="email" href="mailto:cjshaughnessycrane@gmail.com">
        cjshaughnessycrane@gmail.com
      </a>
    </p>
  );
};

export { CareerFormError };
