import React from "react";
import { Card, Button } from "react-bootstrap";

import { Stack } from "../Stack";
import { QuoteButton } from "../QuoteButton";
import { Tiles } from "../Tiles";
import { Equipment } from "../../types";

import "./EquipmentCard.scss";
interface EquipmentCardProps {
  equipment: Equipment;
}

const EquipmentCard = ({ equipment }: EquipmentCardProps) => {
  const { loadChartUrl, imgSrc } = equipment;
  return (
    <Card className="equipment-card">
      <Card.Img variant="top" src={imgSrc}></Card.Img>
      <Card.Body>
        <Stack direction="column" space={20}>
          <div>
            <Card.Title>{equipment.model}</Card.Title>
            <Card.Subtitle>{equipment.manufacturer}</Card.Subtitle>
          </div>
          <div>
            <Tiles className="spec-tiles" columns={2} space="0px">
              {equipment.specs.map((spec, index) => {
                return (
                  <p key={`${equipment.model}-spec-${index}`}>
                    {spec.description} <br />
                    <b>{spec.value}</b>
                  </p>
                );
              })}
            </Tiles>
          </div>

          <Stack direction="column" space={10} sx={{ marginTop: "20px" }}>
            <QuoteButton requestedEquipment={equipment} size="sm">
              Request This {equipment.type}
            </QuoteButton>
            {loadChartUrl && (
              <Button
                variant="outline-primary"
                size="sm"
                href={loadChartUrl}
                target="_blank"
              >
                View Load Charts
              </Button>
            )}
          </Stack>
        </Stack>
      </Card.Body>
    </Card>
  );
};

export { EquipmentCard };
