export type ContractPdf = {
  display: string;
  filename: string;
};

export const contractList: ContractPdf[] = [
  {
    display: "Master Cargo Hauling Agreement",
    filename: "C.J. Shaughnessy - Master Cargo Hauling Agreement 2022.pdf",
  },
  {
    display: "Bill of Lading",
    filename: "C.J. Shaughnessy - Bill of Lading 2022.pdf",
  },
  {
    display: "Short Term Service Agreement",
    filename: "C.J. Shaughnessy - Crane Rental Service Agreement 2022.pdf",
  },
  {
    display: "Leased Employee Agreement",
    filename: "C.J. Shaughnessy - Leased Employee Agreement 2022.pdf",
  },
  {
    display: "500 Bodwell Yard-Warehouse Storage Agreement",
    filename:
      "C.J. Shaughnessy 500 Bodwell - Yard-Warehouse Storage Agreement 2022.pdf",
  },
  {
    display: "520 Bodwell Yard-Warehouse Storage Agreement",
    filename:
      "C.J. Shaughnessy 520 Bodwell - Yard-Warehouse Storage Agreement 2022.pdf",
  },
  {
    display: "Cargo Damage Waiver",
    filename: "C.J. Shaughnessy - Cargo Damage Waiver 2022.pdf",
  },
  {
    display: "Massachusetts Subcontractor Agreement",
    filename: "C.J. Shaughnessy - (MA) Subcontractor Agreement 2023.pdf",
  },
];
