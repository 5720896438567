import React from "react";
import { Card } from "react-bootstrap";

import "./ServiceCard.scss";
interface IServiceCardProps {
  title: string;
  description: string;
  photoUrl: string;
  altText: string;
}

const ServiceCard = ({
  title,
  description,
  photoUrl,
  altText,
}: IServiceCardProps) => {
  return (
    <div>
      <Card className="service-card">
        <Card.Img alt={altText} variant="top" src={photoUrl}></Card.Img>
        <Card.Body>
          <h4 className="service-header">{title}</h4>
          <p className="description-info">{description}</p>
        </Card.Body>
      </Card>
    </div>
  );
};

export { ServiceCard };
