import { useState, useEffect } from "react";

const mobileRegex = /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i;
const iOSRegex = /iPhone|iPad|iPod/i;

const useUserAgent = () => {
  const [isIOS, setIsIOS] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userAgent, setUserAgent] = useState("");

  useEffect(() => {
    const agent = window.navigator === undefined ? "" : navigator.userAgent;
    setUserAgent(agent);
    setIsMobile(Boolean(agent.match(mobileRegex)));
    setIsIOS(Boolean(agent.match(iOSRegex)));
  }, []);

  return { userAgent, isIOS, isMobile };
};

export { useUserAgent };
