import { useState } from "react";
import axios from "axios";

import { Equipment } from "../../types";
import { QuoteValues } from "./types";

const useFormData = (requestedEquipment: Equipment | undefined) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const submitFormData = async (formValues: QuoteValues) => {
    try {
      setIsLoading(true);
      setHasError(false);
      if (requestedEquipment) {
        formValues.requestedEquipment = requestedEquipment.model;
      }

      await axios.post(
        "https://zam4i35byg.execute-api.us-east-1.amazonaws.com/prod/cjs-quote",
        {
          ...formValues,
        }
      );

      setIsLoading(false);
      setShowSuccess(true);
    } catch (err) {
      setIsLoading(false);
      setShowSuccess(false);
      setHasError(true);
    }
  };

  return {
    isLoading,
    hasError,
    showSuccess,
    submitFormData,
  };
};

export { useFormData };
