import * as React from "react";
import { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

import { PersonalInfoValues } from "../types";

interface FormPersonalInfoProps {
  onSubmit: (values: PersonalInfoValues) => void;
  hide: boolean;
}

const FormPersonalInfo = ({ onSubmit, hide }: FormPersonalInfoProps) => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<PersonalInfoValues>({
    name: "",
    email: "",
    phoneNumber: "",
    company: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    const isValid = form.checkValidity() === true;
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
    if (isValid) {
      onSubmit(formData);
    }
  };

  const style = hide ? { display: "none" } : {};
  return (
    <div style={style}>
      <Form
        id={"form-1"}
        onSubmit={handleSubmit}
        validated={validated}
        noValidate
      >
        <Row>
          <Col xs={12} md={6}>
            <Form.Group controlId="name">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                required
                maxLength={200}
                name="name"
                type="text"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please enter your name!
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email Address</Form.Label>
              <Form.Control
                required
                maxLength={200}
                name="email"
                type="email"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a contact email!
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group controlId="formBasicPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                required
                name="phoneNumber"
                maxLength={20}
                type="tel"
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a phone number!
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group controlId="formCompany">
              <Form.Label>Company</Form.Label>
              <Form.Control
                required
                name="company"
                type="text"
                maxLength={200}
                onChange={handleChange}
              />
              <Form.Control.Feedback type="invalid">
                Please provide your company!
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export { FormPersonalInfo };
