import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

import { Stack, CjsJumbotron, CareerForm, PageHelmet } from "components";

const Career = () => {
  return (
    <Container fluid>
      <PageHelmet
        title="Careers - CJ Shaughnessy Crane"
        description="Looking to join our team?"
      />
      <CjsJumbotron
        header="CAREERS"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Stack direction="column" space={80}>
          <div>
            <Row>
              <Col xs={12} md={7}>
                <h3
                  className="text-primary"
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  JOIN OUR TEAM
                </h3>
                <p className="sub-header">Apply Below</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card className="contact-card">
                  <Card.Body>
                    <CareerForm />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Stack>
      </Container>
    </Container>
  );
};

export { Career };
