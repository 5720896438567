import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-NQ8NXMZ",
};

const init = () => {
  TagManager.initialize(tagManagerArgs);
};

const event = (category: string, action: string, label: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "",
      page: window.location.pathname,
    },
  });
};

const pageView = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: "pageview",
      page: window.location.pathname,
    },
  });
};

const gaTracking = { init, event, pageView };
export { gaTracking };
