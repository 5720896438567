import { ICjsEventProp } from "./CjsEvent";

const events: ICjsEventProp[] = [
  {
    eventType: "none",
    header: "CJ Shaughnessy Crane Service is founded.",
    subHeader: "",
    description: "",
    date: "June 2012",
  },
  {
    eventType: "crane",
    header: "New Crane",
    subHeader: "Manitex 35124C Boom Truck Purchased",
    description:
      "CJ Shaughnessy purchases its first crane to start servicing the local area",
    date: "June 2012",
  },
  {
    eventType: "crane",
    header: "New Crane",
    subHeader: "Liebherr LTM 1060-3.1",
    description:
      "With a growing number of projects CJS Crane purchases their first Liebherr crane for use",
    date: "April 2015",
  },
  {
    eventType: "location",
    header: "New Location",
    subHeader: "CJ Shaughnessy Crane moves its shop to Braintree",
    description:
      "With quick access to the highway and a short commute to Boston we found a great location to grow",
    date: "Oct 2015",
  },
  {
    eventType: "crane",
    header: "New Crane",
    subHeader: "Liebherr LTM 1100-4.2",
    description:
      "To be able to bid higher tonnage jobs this four axle Liebherr was put into service",
    date: "2016",
  },
  {
    eventType: "truck",
    header: "New Truck",
    subHeader: "Peterbilt",
    description:
      "CJS Crane starts offering hauling services to help its customers",
    date: "2016",
  },
  {
    eventType: "location",
    header: "New Location",
    subHeader: "CJ Shaughnessy moves to Bodwell St Avon",
    description:
      "CJS Crane purchases its new shop in Avon MA - the expanded shop allows for warehousing customer products and increases capacity for more equipment ",
    date: "July 2018",
  },
  {
    eventType: "crane",
    header: "New Crane",
    subHeader: "Liebherr LTM 1450-8.1",
    description:
      "CJS expands its fleet and lifting capabilities with this 8 axle, 550 ton crane",
    date: "Sept 2019",
  },
  {
    eventType: "location",
    header: "New Location",
    subHeader: "Bodwell Expansion",
    description:
      "CJS purchases the property adjacent to its existing Bodwell Street yard, adding 25,000 square feet of state of the art warehouse space and additional office space",
    date: "June 2021",
  },
  {
    eventType: "crane",
    header: "New Crane",
    subHeader: "Liebherr LTM 1120-4.1",
    description: "CJS puts into service its 9th crane",
    date: "July 2021",
  },
  {
    eventType: "crane",
    header: "New Crane",
    subHeader: "Liebherr LTM 1060-3.1",
    description: "CJS puts into service its 10th crane",
    date: "July 2021",
  },
  {
    eventType: "telehandler",
    header: "New Telehandler",
    subHeader: "Manitou MHT 10180",
    description:
      "CJS purchases this 40K telelhandler with 30 feet of reach, complementing its existing fleet of telescopic forklifts, now ranging from 10K to 40K",
    date: "Jan 2022",
  },
  {
    eventType: "crane",
    header: "New Crane",
    subHeader: "Liebherr LTM 1650-8.1",
    description:
      "CJS purchases its biggest crane to date. The 1650 is now the biggest mobile crane operating in Boston.",
    date: "November 2022",
  },
];

export { events };
