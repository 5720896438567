import React from "react";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import StarIcon from "@material-ui/icons/Star";
import { GiCargoCrane, GiMineTruck, GiForklift } from "react-icons/gi";
import { FaWarehouse } from "react-icons/fa";

import "react-vertical-timeline-component/style.min.css";
import "./CjsEvent.scss";
export interface ICjsEventProp {
  eventType: string;
  header: string;
  subHeader: string;
  description: string;
  date: string;
  overrideIcon?: JSX.Element;
  contentStyle?: any;
  iconStyle?: any;
}

const cjsBlueRgb = "rgb(57, 82, 166)";
const cjsOrangeRgb = "rgb(253, 144, 59)";
const cjsRedRgb = "rgb(173, 38, 44)";

const eventColors: Record<string, string> = {
  crane: cjsBlueRgb,
  location: cjsOrangeRgb,
  truck: cjsRedRgb,
  telehandler: cjsBlueRgb,
};

const eventIcons: Record<string, JSX.Element> = {
  crane: <GiCargoCrane />,
  location: <FaWarehouse />,
  truck: <GiMineTruck />,
  telehandler: <GiForklift />,
  none: <StarIcon />,
};

const CjsEvent = (props: ICjsEventProp) => {
  const { eventType, header, subHeader, description, date } = props;

  const eventColor = eventColors[eventType]
    ? eventColors[eventType]
    : eventColors["crane"];

  const icon = eventIcons[eventType]
    ? eventIcons[eventType]
    : eventColors["none"];

  const iconStyle = { background: eventColor, color: "#fff" };

  return (
    <VerticalTimelineElement
      className={`vertical-timeline-element--${eventType}`}
      contentArrowStyle={{ borderRight: `7px solid  ${eventColor}` }}
      date={date}
      iconStyle={{ ...iconStyle }}
      icon={icon}
    >
      <h3 className="vertical-timeline-element-title">{header}</h3>
      {subHeader && (
        <h4 className="vertical-timeline-element-subtitle">{subHeader}</h4>
      )}
      <p>{description}</p>
    </VerticalTimelineElement>
  );
};

export { CjsEvent };
