import React from "react";
import { Row, Col } from "react-bootstrap";

import { Equipment } from "../../../types";
import { EquipmentCardSmall } from "../../EquipmentCard";
import { Stack } from "../../Stack";

import "./EquipmentSection.scss";

interface EquipmentSectionProps {
  equipment: Equipment[];
}

const renderEquipmentCard = (equipment: Equipment, index: number) => {
  return (
    <div key={index}>
      <EquipmentCardSmall equipment={equipment} />
    </div>
  );
};

const EquipmentSection = ({ equipment }: EquipmentSectionProps) => {
  return (
    <Row>
      <Col>
        <h5 className="equipment-header" style={{ marginTop: "1rem" }}>
          Equipment Used
        </h5>
        <Stack direction="row" space={20} sx={{ padding: "10px" }}>
          {equipment.map(renderEquipmentCard)}
        </Stack>
      </Col>
    </Row>
  );
};

export { EquipmentSection };
