import React from "react";
import { Card } from "react-bootstrap";

import "./JobSpecCard.scss";

interface JobSpecCardProps {
  value: string;
  title: string;
}

const JobSpecCard = ({ value, title }: JobSpecCardProps) => {
  return (
    <div className="spec-wrapper">
      <Card className="spec-card">
        <Card.Body>
          <p>{title}</p>
          <h6 className="spec-value">{value}</h6>
        </Card.Body>
      </Card>
    </div>
  );
};

export { JobSpecCard };
