import * as React from "react";
import { Container, Row, Col } from "react-bootstrap";

import {
  CjsJumbotron,
  Stack,
  QuoteForm,
  ExploreRow,
  PageHelmet,
} from "components";

const Quote = () => {
  return (
    <Container fluid>
      <PageHelmet
        title="Quote - CJ Shaughnessy Crane"
        description="Fill out the form and get a free quote from us!"
      />

      <CjsJumbotron
        header="GET A QUOTE"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Row>
          <Col>
            <Stack
              direction="column"
              space={100}
              sx={{ marginBottom: "100px", marginTop: "100px" }}
            >
              <QuoteForm />
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col>
            <ExploreRow />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Quote };
