import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";

import { ThreeDotsLoading } from "components/ThreeDotsLoading";

import { ApplicationForm } from "./ApplicationForm";
import { ApplicationData } from "../types";

import "./CareerForm.scss";
import { CareerFormError } from "./CareerFormError";

interface CareerFormProps {
  onSubmit: (formValues: ApplicationData) => void;
  isLoading: boolean;
  hasError: boolean;
  onClose?: () => void;
}

const CareerForm = ({
  onSubmit,
  isLoading,
  hasError,
  onClose,
}: CareerFormProps) => {
  const handleSubmit = (data: ApplicationData) => {
    onSubmit(data);
  };

  return (
    <>
      {/* <Row>
        <Col>
          <h5 className="text-primary" style={{ fontWeight: "bold" }}>
            JOIN OUR TEAM
          </h5>
          <h6>Fill out form below to submit a resume</h6>
        </Col>
      </Row> */}
      <Row>
        <Col>
          <ApplicationForm onSubmit={handleSubmit} />
        </Col>
      </Row>

      <Row>
        <Col>
          {isLoading ? (
            <ThreeDotsLoading />
          ) : (
            <>
              {hasError && <CareerFormError />}

              <Button type="submit" form="application-form" variant="primary">
                Submit
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export { CareerForm };
