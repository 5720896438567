import * as React from "react";
import { Row, Col } from "react-bootstrap";

import { Equipment } from "../../types";

import { useFormData } from "./useFormData";
import { FormSteps } from "./FormSteps";
import { FormSuccess } from "./FormSuccess";

import "./QuoteForm.scss";

interface QuoteFormProps {
  onClose?: () => void;
  requestedEquipment?: Equipment;
}

const QuoteForm = ({ onClose, requestedEquipment }: QuoteFormProps) => {
  const { isLoading, hasError, showSuccess, submitFormData } =
    useFormData(requestedEquipment);

  return (
    <Row>
      <Col>
        {showSuccess ? (
          <FormSuccess onClose={onClose} />
        ) : (
          <FormSteps
            isLoading={isLoading}
            hasError={hasError}
            onSubmit={submitFormData}
            onClose={onClose}
          />
        )}
      </Col>
    </Row>
  );
};

export { QuoteForm };
