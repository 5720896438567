import React from "react";
import { Row, Col } from "react-bootstrap";

import { JobSpec } from "../../../types";
import { JobSpecCard, Tiles } from "components";

import "./SpecSection.scss";

interface SpecSectionProps {
  specs: JobSpec[];
}

const SpecSection = ({ specs }: SpecSectionProps) => {
  return (
    <Row>
      <Col>
        <h5 className="spec-header" style={{ marginTop: "1rem" }}>
          Job Specs
        </h5>
        <Tiles
          className="spec-tiles"
          columns={2}
          space="10px"
          sx={{ padding: "10px" }}
        >
          {specs.map((spec, index) => {
            return (
              <JobSpecCard key={index} value={spec.value} title={spec.title} />
            );
          })}
        </Tiles>
      </Col>
    </Row>
  );
};

export { SpecSection };
