import { useEffect } from "react";
import { useUserAgent } from "../useUserAgent";

const useParallax = (
  element: HTMLDivElement | null,
  isControllingClass: boolean = true
) => {
  const { isIOS } = useUserAgent();

  useEffect(() => {
    if (!element || !isControllingClass) {
      return;
    }

    if (isIOS) {
      element.classList.remove("background-fixed");
      return;
    }

    element.classList.add("background-fixed");
  }, [element, isIOS]);
};

export { useParallax };
