import React from "react";
import { Box } from "rebass";

interface ITilesProp {
  columns: number | number[];
  minColumnWidth?: number;
  space?: string;
  sx?: any;
  className?: string;
  children?: React.ReactNode;
}

const Tiles: React.FC<ITilesProp> = (props) => {
  const {
    children,
    columns,
    minColumnWidth = "0",
    space = "0",
    sx,
    className,
  } = props;

  const desiredColumnsArray = typeof columns === "number" ? [columns] : columns;
  const gridTemplateColumns = desiredColumnsArray.map(
    (desiredColumn) =>
      `repeat(${Math.ceil(
        desiredColumn
      )}, minmax(${minColumnWidth}, 1fr)) !important`
  );

  return (
    <Box
      className={className}
      sx={{
        display: "grid",
        gridTemplateColumns,
        gap: space,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export { Tiles };
