import React from "react";
import { Card } from "react-bootstrap";

import { Equipment } from "../../types";

import "./EquipmentCardSmall.scss";

interface EquipmentCardProps {
  equipment: Equipment;
}

const EquipmentCardSmall = ({ equipment }: EquipmentCardProps) => {
  return (
    <Card className="equipment-card-small">
      <Card.Img variant="top" src={equipment.imgSrc}></Card.Img>
      <Card.Body>
        <h6>
          {equipment.manufacturer} {equipment.model}
        </h6>
      </Card.Body>
    </Card>
  );
};

export { EquipmentCardSmall };
