import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { CjsJumbotron, CjsTimeline, Stack, PageHelmet } from "components";
import { cranes } from "routes/equipment";

import logoGuy from "images/CJS-Guy.png";
import "./about.scss";

const serviceYears = new Date().getFullYear() - 2012;
const craneCount = cranes.reduce((acc, crane) => {
  return acc + (crane.quantity ?? 0);
}, 0);

const About = () => {
  return (
    <Container fluid>
      <PageHelmet
        title="About Us - CJ Shaughnessy Crane"
        description="Learn about how CJ Shaughnessy Crane started! Scroll through the timeline of our new cranes, trucks and growth in the Greater Boston area."
      />

      <CjsJumbotron
        header="ABOUT US"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Stack direction="column" space={80}>
          <div>
            <Row>
              <Col xs={12} md={7}>
                <h3
                  className="text-primary"
                  style={{ fontWeight: "bold", marginBottom: "0" }}
                >
                  OUR STORY
                </h3>
                <p className="sub-header">founded in 2012</p>
                <p className="description-info">
                  In 2012, founder Chris Shaughnessy took a risk as a young
                  entrepreneur and purchased his first crane, a 35 ton boom
                  truck. Coming from a family with over 100 years in the crane
                  industry, Chris made a concious decision to break away and
                  start a company of his own. With a focus on reliability,
                  quality service and safety he saw an opportunity to grow.
                  Since then CJ Shaughnessy Crane has done just that servicing
                  the greater Boston area for the past {serviceYears} years. Now
                  operating with {craneCount} cranes along with an entire
                  trucking and warehousing service that is best in class we look
                  forward to projects big and small.
                </p>
              </Col>
              <Col md={3} className="d-none d-md-block">
                <img alt="CJS Crane Guy Logo" className="logo" src={logoGuy} />
              </Col>
            </Row>
          </div>
          <div>
            <h3
              className="text-primary"
              style={{ fontWeight: "bold", marginBottom: "0" }}
            >
              OUR HISTORY
            </h3>
            <p className="sub-header">Scroll through the growth of CJS Crane</p>
            <CjsTimeline />
          </div>
        </Stack>
      </Container>
    </Container>
  );
};

export { About };
