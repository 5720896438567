import { useEffect, useRef } from "react";

const useIntersectionObserver = (
  watchClass: string,
  onAppear: () => void,
  onDisappear: () => void
) => {
  const watchRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    watchRef.current = document.querySelector(watchClass);
    if (watchRef.current === null) {
      return;
    }

    const handler: IntersectionObserverCallback = (entries) => {
      if (entries[0].isIntersecting) {
        onAppear();
      } else {
        onDisappear();
      }
    };

    const intersectionObserver = new IntersectionObserver(handler, {
      rootMargin: "-115px 0px 0px 0px",
    });
    intersectionObserver.observe(watchRef.current);

    return () => {
      if (watchRef.current === null) {
        return;
      }
      intersectionObserver.unobserve(watchRef.current);
    };
  }, [watchClass, onAppear, onDisappear]);
};

export { useIntersectionObserver };
