import * as React from "react";
import { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";

import { JobInfoValues } from "../types";

interface FormJobInfoProps {
  onSubmit: (values: JobInfoValues) => void;
  onBack: () => void;
  hide: boolean;
}

const FormJobInfo = ({ onSubmit, onBack, hide }: FormJobInfoProps) => {
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState<JobInfoValues>({
    jobDescription: "",
    jobAddress: "",
    jobCity: "",
    jobState: "MA",
    jobZip: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    const form = event.currentTarget;
    const isValid = form.checkValidity() === true;
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    if (isValid) {
      onSubmit(formData);
    }
  };

  const style = hide ? { display: "none" } : {};

  return (
    <div style={style}>
      <Form
        id={"form-2"}
        onSubmit={handleSubmit}
        validated={validated}
        noValidate
      >
        <Row>
          <Col>
            <Form.Group controlId="jobDescription">
              <Form.Label>Job Description</Form.Label>
              <Form.Control
                as="textarea"
                name="jobDescription"
                type="text"
                maxLength={600}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formAddress">
              <Form.Label>Job Site Address</Form.Label>
              <Form.Control
                required
                type="text"
                name="jobAddress"
                maxLength={200}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Form.Group as={Col} controlId="formGridCity">
            <Form.Label>City</Form.Label>
            <Form.Control
              required
              name="jobCity"
              maxLength={200}
              onChange={handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridState">
            <Form.Label>State</Form.Label>
            <Form.Control
              required
              name="jobState"
              as="select"
              defaultValue="Choose..."
              onChange={handleChange}
            >
              <option value="MA">MA</option>
              <option value="NH">NH</option>
              <option value="CT">CT</option>
              <option value="RI">RI</option>
              <option value="VT">VT</option>
              <option value="ME">NE</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridZip">
            <Form.Label>Zip</Form.Label>
            <Form.Control
              maxLength={10}
              required
              name="jobZip"
              onChange={handleChange}
            />
          </Form.Group>
        </Row>
      </Form>
    </div>
  );
};

export { FormJobInfo };
