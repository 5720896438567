import * as React from "react";
import { Row, Col } from "react-bootstrap";

import { useFormData } from "./useFormData";
import { CareerForm } from "./Form/CareerForm";
import { FormSuccess } from "./FormSuccess";

interface CareerFormWrapperProps {
  onClose?: () => void;
}

const CareerFormWrapper = ({ onClose }: CareerFormWrapperProps) => {
  const { isLoading, hasError, showSuccess, submitFormData } = useFormData();

  return (
    <Row>
      <Col>
        {showSuccess ? (
          <FormSuccess onClose={onClose} />
        ) : (
          <CareerForm
            isLoading={isLoading}
            hasError={hasError}
            onSubmit={submitFormData}
            onClose={onClose}
          />
        )}
      </Col>
    </Row>
  );
};

export { CareerFormWrapper as CareerForm };
