import React from "react";
import { useRef } from "react";
import { Jumbotron, Container, Row, Col } from "react-bootstrap";
import { QuoteButton } from "../QuoteButton";
import { Stack } from "../Stack";

import { useParallax } from "../../hooks";

import "./jumbotron.scss";

interface ICjsJumbotronProps {
  header?: string;
  showButton?: boolean;
  message?: string;
  size?: string;
}

const defaultMessage = `CJ Shaughnessy Crane is the trusted provider of mobile crane services, rigging, and heavy haul transport for
contractors throughout New England.`;

const CjsJumbotron = (props: ICjsJumbotronProps) => {
  const {
    header = "WELCOME",
    showButton = true,
    message = defaultMessage,
    size = "large",
  } = props;

  const jumbotronElement = useRef<HTMLDivElement | null>(null);
  useParallax(jumbotronElement.current, size === "large");

  return (
    <Jumbotron className={`cjs-jumbotron ${size}`} fluid ref={jumbotronElement}>
      <div className="overlay">
        <Container>
          <Row className="main-content">
            <Col>
              <Row bsPrefix="row d-none d-sm-flex">
                <Col>
                  <Stack direction="row" space={30}>
                    <h1>{header}</h1>

                    {showButton && (
                      <div className="button-col">
                        <QuoteButton variant="danger" />
                      </div>
                    )}
                  </Stack>
                  <Row>
                    <Col md={9} xs={12}>
                      <h2>{message}</h2>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row bsPrefix="row d-xs-flex d-sm-none">
                <Col>
                  <Stack direction="column" space={5}>
                    <h1>{header}</h1>
                    <Row>
                      <Col md={9} xs={12}>
                        <h2>{message}</h2>
                      </Col>
                    </Row>
                  </Stack>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </Jumbotron>
  );
};

export { CjsJumbotron };
