import React from "react";
import { Row, Col } from "react-bootstrap";
import { ServiceCard, Stack } from "../../components";

import bose from "../../images/BosePick.jpg";
import hauling from "../../images/hauling-2.jpg";
import rigging from "../../images/rigging-1-small.jpg";

import "./ServicesSection.scss";

interface ServiceCardInfo {
  title: string;
  description: string;
  photoUrl: string;
  altText: string;
}

const services: ServiceCardInfo[] = [
  {
    title: "HOISTING",
    description: `We are experts at properly planning your lift, ensuring a
  safe and successful placement of your equipment. We leave
  nothing to chance and carefully plan your lift before it
  takes place.`,
    photoUrl: bose,
    altText: "CJS Crane Bose Hositing Lift",
  },
  {
    title: "RIGGING",
    description: `At CJS Crane, we are routinely entrusted with moving
  equipment worth several million dollars. Planning and safety
  are our guiding principles. Regardless of how tight the
  surroundings or the opening, we have the right equipment to
  do the job.`,
    photoUrl: rigging,
    altText: "CJS Crane Rigging",
  },
  {
    title: "HAULING",
    description: `We have years of experience transporting unique, over
  dimensioned loads, and we make sure your equipment arrives
  safely. We only work with the industry's best equipment ensuring that if the job can be done we can do it.`,
    photoUrl: hauling,
    altText: "CJS Crane Truck Hauling",
  },
];

const ServicesSection = () => {
  return (
    <>
      <h3
        className="text-primary"
        style={{ fontWeight: "bold", marginBottom: "0" }}
      >
        OUR SERVICES
      </h3>
      <p className="sub-header">hoisting - rigging - hauling</p>
      <Row>
        <Col xs={12} md={8}>
          <p className="description-info">
            At CJ Shaughnessy Crane Service, we focus on exceptional service for
            all your mobile hoisting and rigging needs. We realize all mobile
            hoisting and rigging jobs are not the same. Our goal is to form long
            term, professional relationships with customers, and our flexible
            scheduling options are designed to fit your needs.
          </p>
        </Col>
      </Row>

      <Row bsPrefix="row d-sm-flex d-md-none service-row">
        <Stack
          className="vertical-services"
          direction="column"
          space={20}
          sx={{ padding: "10px", marginLeft: "auto", marginRight: "auto" }}
        >
          {services.map((service: ServiceCardInfo) => {
            return (
              <ServiceCard
                title={service.title}
                description={service.description}
                photoUrl={service.photoUrl}
                altText={service.altText}
              />
            );
          })}
        </Stack>
      </Row>

      <Row bsPrefix="row d-none d-md-flex service-row">
        <Stack
          direction="row"
          space={20}
          sx={{ padding: "10px", margin: "10px" }}
        >
          {services.map((service: ServiceCardInfo) => {
            return (
              <ServiceCard
                title={service.title}
                description={service.description}
                photoUrl={service.photoUrl}
                altText={service.altText}
              />
            );
          })}
        </Stack>
      </Row>
    </>
  );
};

export { ServicesSection };
