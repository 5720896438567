import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation, useHistory } from "react-router";
import { ImPhone } from "react-icons/im";
import { Container, Navbar, Nav, Button } from "react-bootstrap";

import { gaTracking } from "services";
import { Stack, QuoteButton } from "components";

import { useDynamicNavbar } from "./useDynamicNavbar";

import logo from "images/cjs-logo-trim-small.png";

import "./navbar.scss";

const NavBar = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    return history.listen(() => {
      gaTracking.pageView();
    });
  }, [history]);

  const { navBarEl, handleOnToggle } = useDynamicNavbar();

  return (
    <Navbar
      ref={navBarEl}
      variant="dark"
      bg="clear"
      fixed="top"
      expand="lg"
      collapseOnSelect
      onToggle={handleOnToggle}
    >
      <Container>
        <Stack direction="column" sx={{ width: "100%" }} space={0}>
          <Stack
            direction="row"
            sx={{ alignItems: "flex-start", width: "100%" }}
            space={0}
          >
            <Navbar.Brand href="/">
              <img src={logo} alt="CJS Logo" />
            </Navbar.Brand>
            <Stack
              direction="column"
              sx={{ flex: "2 1 auto", gap: "8px" }}
              space={0}
            >
              <Stack
                direction="row"
                space={10}
                sx={{ marginLeft: "auto", alignItems: "center" }}
              >
                {/* <Button className="quote-button" variant="primary" size="sm">
                  <div className="phone-wrapper">
                    <a className="phone" href="tel:+1-781-315-5321">
                      <ImPhone fill="white" />
                    </a>
                  </div>
                </Button> */}
                <QuoteButton size="sm">Get A Quote</QuoteButton>
              </Stack>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Navbar.Toggle />
              </Container>
              <Navbar.Collapse>
                <Nav className="ml-auto" activeKey={location.pathname}>
                  <Nav.Item>
                    <Nav.Link eventKey="1" as={NavLink} to="/" exact>
                      Home
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2" as={NavLink} to="/about">
                      About
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3" as={NavLink} to="/work">
                      Our Work
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4" as={NavLink} to="/equipment">
                      Equipment
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="5" as={NavLink} to="/contact">
                      Contact Us
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="6" as={NavLink} to="/contracts">
                      Contracts
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="7" as={NavLink} to="/careers">
                      Careers
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar.Collapse>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Navbar>
  );
};

export { NavBar };
