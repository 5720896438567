import { useRef } from "react";
import { useIntersectionObserver } from "hooks";

const addBg = (navBar: HTMLDivElement | null, bgClass: string) => {
  if (navBar === null) {
    return;
  }
  navBar.classList.add(bgClass);
};

const removeBg = (navBar: HTMLDivElement | null, bgClass: string) => {
  if (navBar === null) {
    return;
  }
  navBar.classList.remove(bgClass);
};

const useDynamicNavbar = () => {
  const navBarEl = useRef<HTMLDivElement | null>(null);

  const handleJumboAppearing = () => {
    removeBg(navBarEl.current, "dark-background");
  };

  const handleJumboDisappearing = () => {
    addBg(navBarEl.current, "dark-background");
  };

  const handleOnToggle = (expanded: boolean) => {
    if (navBarEl.current === null) {
      return;
    }

    if (expanded) {
      addBg(navBarEl.current, "open-background");
      return;
    }

    removeBg(navBarEl.current, "open-background");
  };

  useIntersectionObserver(
    ".cjs-jumbotron",
    handleJumboAppearing,
    handleJumboDisappearing
  );

  return { handleOnToggle, navBarEl };
};

export { useDynamicNavbar };
