import React from "react";
import { useState } from "react";
import { Stack } from "../Stack";
import { Card, Row, Col } from "react-bootstrap";

import { Job } from "../../types";
import { JobModal } from "../JobModal";

import "./JobSummaryCard.scss";

interface JobSummaryCardProps {
  job: Job;
}

const JobSummaryCard = ({ job }: JobSummaryCardProps) => {
  const { title, date, location, summaryPhoto } = job;

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  return (
    <Card className="job-summary-card">
      <Card.Body onClick={handleShow}>
        <img
          alt={`${title} cover`}
          className="job-summary-image"
          src={summaryPhoto}
        />
        <div className="summary-overlay"></div>
        <Row className="job-summary-details">
          <Col>
            <h3>{title}</h3>
            <Row>
              <Col>
                <Stack direction="row" space={20}>
                  <p>{date}</p>
                  <p>{location}</p>
                </Stack>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
      {show && <JobModal job={job} show={show} onHide={handleClose} />}
    </Card>
  );
};

export { JobSummaryCard };
