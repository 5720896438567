import React from "react";
import { Carousel } from "react-bootstrap";

import "./PhotoCarousel.scss";

interface PhotoSectionProps {
  photoUrls: string[];
}

const renderPhoto = (photoUrl: string, index: number) => {
  return (
    <Carousel.Item key={`carousel-photo-${index}`}>
      <div className="img-wrapper-outer">
        <div className="img-wrapper-inner">
          <img
            className="d-block carousel-img"
            src={photoUrl}
            alt={`${index} slide`}
          />
        </div>
      </div>
    </Carousel.Item>
  );
};

const PhotoCarousel = ({ photoUrls }: PhotoSectionProps) => {
  return (
    <Carousel className="photo-carousel">{photoUrls.map(renderPhoto)}</Carousel>
  );
};

export { PhotoCarousel };
