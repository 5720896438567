import * as React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import { CjsJumbotron, Stack, ExploreRow, PageHelmet } from "components";

import { contractList } from "./contractList";

const Contracts = () => {
  return (
    <Container fluid>
      <PageHelmet
        title="Contracts - CJ Shaughnessy Crane"
        description="Download our available work contracts"
      />
      <CjsJumbotron
        header="CONTRACTS"
        showButton={false}
        message=""
        size="equipment"
      />
      <Container className="page-container">
        <Row>
          <Col xs={12} md={8}>
            <h3
              className="text-primary"
              style={{ fontWeight: "bold", marginBottom: "0" }}
            >
              DOWNLOAD
            </h3>
            <p className="sub-header">
              CLICK TO DOWNLOAD OR VIEW OUR CONTRACTS{" "}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Stack
              direction="column"
              space={16}
              sx={{ marginBottom: "100px", marginTop: "16px" }}
            >
              {contractList.map((contract) => {
                return (
                  <Button
                    variant="primary"
                    size="lg"
                    href={`/contractFiles/${contract.filename}`}
                    target="_blank"
                  >
                    {contract.display}
                  </Button>
                );
              })}
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col>
            <ExploreRow />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export { Contracts };
